import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseVendorAPI, IDocumentTypeData } from 'sustainment-component';

@Injectable()
export class DocumentTypeAPI extends BaseVendorAPI {
  private _relativeUrl = 'DocumentType';

  public getDocumentTypesData(): Observable<IDocumentTypeData[]> {
    return this.get<IDocumentTypeData[]>(this._relativeUrl);
  }
}
