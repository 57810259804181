import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IDocumentTypeState } from 'sustainment-component';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'documentTypeInfo' })
export class DocumentTypeStore extends Store<IDocumentTypeState> {
  public constructor() {
    super({});
  }
}
